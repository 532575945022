import api from '@/api/clientsLogos'

export default {
	name: 'Admin',
	data () {
		return {
			valid: false,
			loading: false,
			limit: 10,
			page: 1,
			formData: {
				image: null
			}
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			api.getPagination(this, this.offset, this.limit)
		},
		remove(id) {
			this.$root.$emit('ConfirmingModal', true, {
				title: "Delete item",
				text: "Sure delete this item?",
				action_title: 'Delete',
				action: () => {
					api.delete(this, id)
				}
			})
		},
		create () {
			if (this.$refs.form.validate()) {
				this.loading = true
				let data = new FormData()
				Object.keys(this.formData).map(key => {
					data.append(key, this.formData[key])
				})

				api.create(this, data)
					.then(() => {
						this.formData = { image: null }
						this.page = 1
						this.getData()
					})
					.finally(() => {
						this.loading = false
					})
			}
		}
	},
	computed: {
		offset () {
			return this.limit * (this.page -1)
		},
		tableData () {
			if (this.state?.clientsLogos?.data) {
				return this.state.clientsLogos.data
			}
			return []
		},
		countButtons () {
			if (this.state?.clientsLogos?.meta) {
				return parseInt((this.state.clientsLogos.meta.count / this.limit) +1)
			}
			return 1
		}
	}
}
